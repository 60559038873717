import * as amplitude from "@amplitude/analytics-browser";

const apiKey =
  window.location.hostname === "tartaleta.art"
    ? "dfee3dd2868d9533e7b3bf5875e15659"
    : "d086924cd5ca9f01efa1ba715ada2032";

amplitude.init(apiKey, {
  serverZone: "EU",
  defaultTracking: true,
});
